<template>
	<div class="layout-profile">
		<div>
			<img style="width: 40%;" :src="url+$store.state.foto+'.png'" v-if="$store.state.foto=='foto'"/>
			<img :src="anexos+'/ver/'+$store.state.foto+'.jpg'" v-else/>
		</div>
		<button class="p-link layout-profile-link" @click="onClick">
			<span class="username">{{$store.state.usuario}}</span>
			<i class="bi pi-fw bi-pen"></i>
			<br><span class="username">{{$store.state.nucleo}}</span>
		</button>
		<div v-if="$store.state.autenticado" style="color: white;">
			<span class="username"><i class="bi bi-people-fill"></i> Conectados: {{$store.state.conectados}}</span>
		</div>
        <transition name="layout-submenu-wrapper">
            <ul v-show="expanded">
                <li><button class="p-link" @click="openPerfil()"><i class="pi pi-fw pi-user"></i><span>Perfil</span></button></li>
                <li><button class="p-link" @click="openClave()"><i class="pi pi-fw pi-key"></i><span>Cambio de Clave</span></button></li>
                <li><button class="p-link" @click="Logout()"><i class="pi pi-fw pi-power-off"></i><span>Salir</span></button></li>
            </ul>
        </transition>
	</div>
	<Perfil :display="displayPerfil" @aprob-perfil="onMenuToggle" @close-perfil="close"/>
	<Clave :display="displayClave" @aprob-clave="onMenuToggle" @close-clave="close"/>
</template>

<script>
	import { defineAsyncComponent } from "@vue/runtime-core";
	
	export default {
		components: {
			Perfil: defineAsyncComponent(() => import('./components/Perfil.vue')),
			Clave: defineAsyncComponent(() => import('./components/CambioClave.vue')),
		},
		data() {
			return {
				expanded: false,
				displayPerfil: false,
				displayClave: false,
				url: null,
				anexos: null,
			}
		},
		created() {
			this.anexos = process.env.VUE_APP_ANEXOS;
			//this.url = process.env.VUE_APP_API+'foto/';
		},
		methods: {
			onMenuToggle(event) {
				this.$emit('menu-toggle', event);
			},
			onClick(event){
				this.expanded = !this.expanded;
				event.preventDefault();
			},
				openPerfil() {
					this.$store.commit('Mobile');
					//this.displayPerfil = true;
				},
				openClave() {
					this.$store.commit('Mobile');
					this.displayClave = true;
				},
				close() {
					this.displayPerfil = false;
					this.displayClave = false;
				},
				Logout() {
				this.$store.commit('Logout');
				this.$router.push({ path: '/' });
				}

		}
	}
</script>

<style scoped>

</style>